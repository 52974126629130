<template>
  <el-dialog
    :visible.sync="describeDialogVisData"
    :close-on-click-modal="true"
    append-to-body
    center
    width="80%"
  >
    <h2>夺宝屋介绍</h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      夺宝屋（网址：www.duobaowu.vip）是京东拍拍夺宝岛抢拍的一件新锐利器，软件在线使用，无需下载安装。本软件采用了强大而精妙的抢拍算法，能助您在当下用软件竞拍激烈的竞争环境中脱颖而出，
      在保证超高的抢拍成功率（90%以上）的同时，以尽可能低的价格拍到您心仪的商品。
    </p>
    <h2 style="color:red">夺宝屋强悍之处</h2>
    <p style="font-size:18px;color:red;margin-left:30px;line-height: 27px;">
      京东拍拍夺宝岛的备件库自营商品竞争激烈，各种抢拍软件层出不穷，夺宝屋的优势在哪里？<br />
      举个例子：假如一台显示器有10个人在同时竞拍，其中9个人都是人工在出价，而你用软件出价，此时无论您用本软件还是其他抢拍软件，您定能获拍。
      但是目前专业卖家都在用软件进行抢拍，那在一定价格范围内，能否获拍将直接取决于软件的性能，本软件依靠独特而强大的抢拍算法，
      在大家都用软件抢拍的情况下，综合成功率稳定在90%以上！纵观全网，舍我其谁？！<br />
    </p>
    <h2 style="color:#ff9b00">3天免费试用</h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      夺宝屋目前正在开放公测，公测期间注册账号需要使用邀请码，需要注册账号请微信（18630715494）联系管理员索要邀请码，完成注册后即可享受3天免费试用。
    </p>
    <h2 style="color:#d811e9">软件特色</h2>

    <p style="font-size:18px;color:;margin-left:30px;line-height: 27px;">
      <span style="color:#d811e9">网页在线使用：</span
      >无需下载安装，网页打开即用，抢拍任务云端执行，本地电脑可随时关机。<br />
      <span style="color:#d811e9">京东账号扫码登录：</span
      >京东账号可通过手机app一键扫码登录。<br />
      <span style="color:#d811e9">多账号管理：</span
      >可以管理多个京东夺宝账号，可以为不同的夺宝任务指定不同的夺宝账号。<br />
      <span style="color:#d811e9">价格表：</span
      >可以将关注的商品添加到价格表，并设置常用的夺宝价格。<br />
      <span style="color:#d811e9">自动夺宝：</span
      >根据价格表里添加的商品记录，每天自动创建对应商品的夺宝任务。<br />
      <span style="color:#d811e9">出价记录查看：</span
      >可以查看商品的出价记录。<br />
      <span style="color:#d811e9">多维度商品查询：</span
      >支持商品品牌、名称、成色、全新价格等多维度检索当天的夺宝商品。<br />
      <span style="color:#d811e9">夺宝历史查询：</span
      >所有夺宝记录均可查询。<br />
      <span style="color:#d811e9">消费记录查看：</span
      >清晰、明了的消费明细记录，并有多维度统计数据。<br />
      <span style="color:#d811e9">饥饿模式：</span
      >只需告诉软件某件商品的“饥饿值”（你今天需要拍几个该类商品），软件会自动抢拍，直到“吃饱为止”。<br />
      <span style="color:#d811e9">智能价格推荐：</span
      >根据最近成交历史，智能推荐捡漏价格、合理价格、稳妥价格，无需用户再浪费脑筋计算！<br />
      <span style="color:#d811e9">更多智能功能持续更新中....</span><br />
    </p>
    <!-- <h2 style="color:red">收费模式</h2>
    <p style="font-size:18px;color:#000;margin-left:30px;line-height:27px;">
      本软件收费模式是<strong>充值月度会员制</strong>，一次充值根据充值数额分为白银、黄金、铂金、大客户四级会员，账号内会带有等量充值余额，夺宝成功会按一定费率（成交价百分比）消费余额，不成功仅收取资源消耗费用0.1元。账号的会员级别当月有效，次月执行会员降级制度。<br />
      <span style="color:red">会员降级制度：月度会员充值后有效期为30天，到期后自动降一级，直到掉到白银会员，白银会员再到期账号将被冻结，需重新开通会员即可使用。</span>
    </p>
    <el-table :data="memberTableData" style="width: 100%;text-align:center">
      <el-table-column
        align="center"
        prop="memberShip"
        label="会员权益"
        width="400"
      >
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.memberShip }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="level0" label="非会员">
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.level0 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="level1" label="白银会员">
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.level1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="level2" label="黄金会员">
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.level2 }}
          </span>
        </template> </el-table-column
      ><el-table-column align="center" prop="level3" label="铂金会员">
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.level3 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="level4" label="大客户">
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.level4 }}
          </span>
        </template>
      </el-table-column>
    </el-table> -->
  </el-dialog>
</template>
<script>
export default {
  name: "Description",
  data() {
    return {
      describeDialogVisData: this.describeDialogVis,
    };
  },
  props: {
    describeDialogVis: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    describeDialogVisData(n, o) {
      this.$emit("update:describeDialogVis", n);
    },
    describeDialogVis(n, o) {
      this.describeDialogVisData = n;
    },
  },
};
</script>

<style scoped>
.yellowTime {
  margin-left: 5px;
  font-size: 15px;
  color: #ffc02d;
}
.greenTime {
  margin-left: 5px;
  font-size: 15px;
  color: #00f335;
}
.yellowPrice {
  color: #ffc02d;
}
.greenPrice {
  color: #00f335;
}
.greyPrice {
  color: #000000;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/system/index/components/d2-page-cover/Description.vue"
}
</vue-filename-injector>
