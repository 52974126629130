<template>
  <el-dialog
    title="夺宝屋常见问题"
    :visible.sync="tipsDialogVisData"
    :close-on-click-modal="true"
    append-to-body
    center
    width="80%"
  >
    <h2 style="color:red">
      手机端登录介绍
    </h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      夺宝岛分为网页端和手机端，本站原扫码登录是网页端登录，该方式弊端有：1、登陆一次有效期只有1天，次日需要再次登录。2、出价不稳定，容易出现“出价超时”等问题。相对的，手机端登录一次有效期为30天，且出价更稳定，部分商品如“小米”、“相机”等手机端出价不会超时。
      手机端登录方式：由于手机端登录需要进行人工验证，请在账号管理里面准确录入京东账号和密码，然后通知管理员进行后台操作登录即可。一次登录，有效期30天。
    </p>
    <!-- <h2 style="color:red">
      什么是“权重”玩法？
    </h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      可以给自己的夺宝任务设置“权重”，抢拍时，软件服务器资源分配按权重分配。当前所有夺宝任务的权重默认都是1，即服务器资源平均分配到各个夺宝任务。现可手动增加夺宝任务的“权重”，可将权重设置为
      “2、3、4、5”倍。对应的，如果你成功获拍该商品。软件扣取佣金为：正常佣金*权重。
      举个栗子：1、当前有个显示器，有A、B、C在拍，而C已经将显示器卖出，十分渴望拿到该商品，由于软件是平均分配服务器资源，C获拍的概率是33%(三人权重为1:1:1，单纯从软件资源角度考虑)，此时C发现该显示器同平台竞争较激烈，可以将该夺宝任务设置权重为2。则获拍概率是50%（三人权重为1:1:2）。如此可见，服务器资源得以倾斜。假设该商品获拍应扣3元，因为C是加了权重（权重为2）后成功获拍，则扣费为：3*2=6元。
    </p> -->
    <h2 style="color:red">京东账号经常"出价超时"，影响夺宝怎么办？</h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      可以多登陆几个账号，然后在账号设置里开启“自动切换主账号”，软件在夺宝前会检测主账号的可用性，如过有问题会自动切换其他可用账号，建议每天登陆3-5个京东账号。
    </p>
    <h2 style="color:red">月度会员到期后，执行的“降级制度”是什么意思？</h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      会员降级制度：月度会员充值后有效期为30天，到期后自动降一级，直到掉到白银会员。也就是说，您购买大客户会员后，第一个月是大客户，第二个月是铂金会员，第三个月是黄金会员，第四个月是白银会员，白银会员账号永不过期。
    </p>
    <h2 style="color:red">为什么需要设置价格区间，而不是直接设置最高价？</h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      本软件的核心抢拍算法不同于网上其他软件，其他软件大多是获取最新报价然后加几块钱出价，这种算法有天生缺陷，成功率难以保证。本软件抢拍算法别出心裁，需要一个合适的出价区间才能将抢拍性能发挥到极限！
    </p>
    <h2 style="color:red">价格区间如何设置效果最好？</h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      价格区间大小直接影响出价精度，出价精度直接影响获拍后超出倒数第二价格的多少，价格区间差值建议在<span
        style="color:red"
        >30-200</span
      >间为好，差值越大，报价精度越低。
    </p>

    <h2 style="color:red">
      获拍后，为什么有的时候比第二高1元，有的时候高3元、5元甚至更多？
    </h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      大多数情况是由于出价记录不准，有很多报价没有显示出来。除此之外，夺宝价格区间决定出价精度，区间越小，精度越高，差值也就会越小。
    </p>
    <h2 style="color:red">
      京东账号多久登录一次，才会避免出现拍号未登录的情况？
    </h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      京东账号早上扫码登录后，能够保持一天的登录有效状态，次日则需要重新登录，所以建议每天至少扫码登录一次。
    </p>
    <h2 style="color:red">
      为什么京东账号在早上扫码登录成功了，在8点多的时候却出价超时了？
    </h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      京东在8点多有账号检测机制，部分账号在8点-8点50分会被限制出价，一般在8点50分之前会自动恢复正常可用状态。
    </p>
    <h2 style="color:red">
      为什么有的时候成交价在设置的夺宝价格区间内，还是没拍到？
    </h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      失败的原因分两种：一是账号问题，包括未登录、出价超时、夺宝成功未履约被封号、没有设置主账号等；二是软件原因，有两种可能：1、网络波动，不定时，偶发。表现为没有出价。2、被别的软件抢到了，概率极低，表现为出价后被超越。
    </p>
    <h2 style="color:red">
      为什么账号刚刚还好好的，再拍却出价超时了？
    </h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      京东的账号限制比较多，在本软件用的拍号，尽量不要在手机端进行出价拍东西，可以减小出价超时的概率。
    </p>
    <h2 style="color:red">
      什么是饥饿模式？
    </h2>

    <p style="font-size:18px;color:#000;margin-left:30px;line-height: 27px;">
      在饥饿模式下，创建任务时可以设置该夺宝商品的饥饿值，此任务未拍中则自动拍下一个同类商品，
      拍中则饥饿值-1 ，直到饥饿值为0
      ，仅当日有效。适合“当天有很多同一型号/成色商品，但只需要拍1或2个就可以”的场景。
    </p>
  </el-dialog>
</template>
<script>
export default {
  name: "Tips",
  data() {
    return {
      tipsDialogVisData: false,
    };
  },
  props: {
    tipsDialogVis: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    tipsDialogVisData(n, o) {
      this.$emit("update:tipsDialogVis", n);
    },
    tipsDialogVis(n, o) {
      this.tipsDialogVisData = n;
    },
  },
};
</script>

<style scoped>
.yellowTime {
  margin-left: 5px;
  font-size: 15px;
  color: #ffc02d;
}
.greenTime {
  margin-left: 5px;
  font-size: 15px;
  color: #00f335;
}
.yellowPrice {
  color: #ffc02d;
}
.greenPrice {
  color: #00f335;
}
.greyPrice {
  color: #000000;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/system/index/components/d2-page-cover/Tips.vue"
}
</vue-filename-injector>
