<template>
  <div>
    <span class="primary" style="margin: 0px 20px 0px 20px">
      {{ greetings }}{{ user.member.name }}【{{ info.name }}】<span
        style="color: red"
        v-if="user.member.level === 0"
        >，剩余免费夺宝次数：<span style="color: red">{{
          user.freeShots + " "
        }}</span
        >次</span
      >
      <span
        style="color: red"
        v-if="
          user.quota < 500 && user.member.level > 0 && user.member.level <= 5
        "
        >，余额剩余：<span style="color: red">
          {{ user.quota + " " }}元
        </span></span
      >
    </span>
    <Dropdown class="d2-mr">
      <span style="font-size: 15px" class="primary">
        <strong>系统菜单</strong>
      </span>
      <DropdownMenu slot="dropdown" style="width: 220px; text-align: left">
        <div style="margin-bottom: 10px; padding: 0px 0px 0px 18px">
          <p>
            <d2-icon name="user-circle" class="d2-mr-5" />
            {{ info.name }} {{ greetings }}
          </p>
        </div>
        <DropdownItem @click.native="openMore" divided>
          <d2-icon name="address-book-o" class="d2-mr-10" /><span
            style="font-size: 16px"
            >账户设置</span
          >
        </DropdownItem>
        <!-- <DropdownItem @click.native="openDescribe" divided>
          <d2-icon name="info-circle" class="d2-mr-10" /><span
            style="font-size: 16px"
            >软件介绍</span
          >
        </DropdownItem> -->
        <!-- <DropdownItem @click.native="openMember" divided>
          <d2-icon name="gift" class="d2-mr-10" /><span style="font-size: 16px"
            >会员充值</span
          >
        </DropdownItem> -->
        <DropdownItem @click.native="openTips" divided>
          <d2-icon name="question-circle" class="d2-mr-10" /><span
            style="font-size: 16px"
            >常见问题</span
          >
        </DropdownItem>
        <DropdownItem @click.native="openModifyPassword" divided>
          <d2-icon name="question-circle" class="d2-mr-10" /><span
            style="font-size: 16px"
            >修改密码</span
          >
        </DropdownItem>
        <DropdownItem @click.native="logOff" divided>
          <d2-icon name="power-off" class="d2-mr-10" /><span
            style="font-size: 16px"
            >退出系统</span
          >
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
    <el-drawer
      :withHeader="false"
      :show-close="false"
      :visible.sync="drawer"
      :direction="direction"
      size="20%"
      ><div style="margin-left: 20px">
        <div style="font-size: 20px; margin-bottom: 20px">
          <div style="margin-bottom: 15px">
            <span><strong>账号信息</strong></span>
          </div>
          <div
            style="
              margin-left: 30px;
              font-size: 15px;
              margin-bottom: 10px;
              color: #868585;
            "
          >
            <span>用户名：{{ info.username }}</span>
          </div>
          <div
            style="
              margin-left: 30px;
              font-size: 15px;
              margin-bottom: 10px;
              color: #868585;
            "
          >
            <span>中文名：{{ info.name }}</span>
          </div>
          <div
            style="
              margin-left: 30px;
              font-size: 15px;
              margin-bottom: 10px;
              color: #868585;
            "
          >
            <span>手机号：</span>
            <el-input
              v-if="editPhoneNumber"
              style="width:120px;height:20px"
              @blur="modifyPhoneNumber"
              v-model="info.phoneNumber"
            ></el-input
            ><el-button
              v-if="editPhoneNumber"
              style="margin-left:10px;padding:0px;"
              type="text"
              @click="modifyPhoneNumber"
              >保存</el-button
            >
            <span v-if="!editPhoneNumber">{{ info.phoneNumber }}</span
            ><el-button
              v-if="!editPhoneNumber"
              style="margin-left:10px;padding:0px;"
              type="text"
              @click="editPhoneNumber = true"
              >修改</el-button
            >
          </div>
        </div>
        <div style="font-size: 20px; margin-bottom: 20px">
          <div style="margin-bottom: 15px">
            <span><strong>会员信息</strong></span>
          </div>
          <div style="font-size: 17px; margin-left: 30px">
            <div style="margin-bottom: 5px; color: maroon">
              <span>会员级别</span>
            </div>

            <div
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
              "
            >
              <span>会员等级：{{ user.member.name }}</span>
            </div>
            <div
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
              "
            >
              <span
                >会员剩余天数：{{
                  user.member.level === 1
                    ? "无限制"
                    : parseInt(
                        (user.expireTime - new Date().getTime()) /
                          (1000 * 60 * 60 * 24)
                      ) + "天"
                }}</span
              >
            </div>
            <div
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
              "
            >
              <span>会员到期时间：{{ fmtDate(user.expireTime) }}</span>
            </div>
            <div
              v-if="user.member.level > 0"
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
              "
            >
              <span>我的邀请码：{{ user.inviteCode }}</span>
            </div>
          </div>
          <div
            style="font-size: 17px; margin-left: 30px"
            v-if="user.member.level < 5"
          >
            <div style="margin-bottom: 5px; color: maroon">
              <span>消费信息</span>
            </div>
            <div
              v-if="user.member.level === 0"
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
              "
            >
              <span>剩余免费夺宝次数：{{ user.freeShots }}次</span>
            </div>
            <div
              v-if="user.member.level > 0"
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
              "
            >
              <span>当前账户余额：{{ user.quota }}元</span>
            </div>
            <div
              v-if="user.member.level > 0"
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
              "
            >
              <span>夺宝失败消费：{{ user.member.fixedMinus }}元</span>
            </div>
            <div
              v-if="user.member.level > 0"
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
              "
            >
              <span>夺宝成功费率：{{ user.member.successMinus * 100 }}%</span>
            </div>
            <div
              v-if="user.member.level > 0"
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
              "
            >
              <span>夺宝消费最低：{{ user.member.minMinus }}元</span>
            </div>
            <div
              v-if="user.member.level > 0"
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
              "
            >
              <span>夺宝消费最高：{{ user.member.maxMinus }}元</span>
            </div>
          </div>
          <!-- <div style="font-size:17px;margin-left:30px">
            <div style="margin-bottom:5px;color:maroon">
              <span>智能功能</span>
            </div>
            <div
              style="margin-left:30px;font-size:15px;margin-bottom:10px;color:#868585;"
            >
              <span
                >饥饿模式：{{
                  user.member.hungryMode === true ? "支持" : "不支持"
                }}</span
              >
            </div>
          </div> -->
        </div>

        <div style="font-size: 20px; margin-bottom: 20px">
          <div style="margin-bottom: 15px">
            <span><strong>功能设置</strong></span>
          </div>
          <div style="font-size: 17px; margin-left: 30px">
            <div style="margin-bottom: 5px; color: maroon">
              <span>夺宝设置</span>
            </div>
            <div
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
                width: 200px;
              "
            >
              <span>自动夺宝（价格表）：</span>
              <el-switch
                style="float: right"
                v-model="user.config.autoCreateTask"
                active-color="#13ce66"
                inactive-color="#b5b1b1"
                @change="saveUserConfig"
              ></el-switch>
            </div>
            <div
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
                width: 200px;
              "
            >
              <span>自动切换主账号：</span>
              <el-switch
                style="float: right"
                v-model="user.config.autoChangeMainAccount"
                active-color="#13ce66"
                inactive-color="#b5b1b1"
                @change="saveUserConfig"
              ></el-switch>
            </div>
            <div
              v-if="info.level >= 8"
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
                width: 200px;
              "
            >
              <span>app接口出价：</span>
              <el-switch
                style="float: right"
                v-model="user.config.appApiOffer"
                active-color="#13ce66"
                inactive-color="#b5b1b1"
                @change="saveUserConfig"
              ></el-switch>
            </div>
          </div>
          <div style="font-size: 17px; margin-left: 30px">
            <div style="margin-bottom: 5px; color: maroon">
              <span>短信设置（0.1元/条）</span>
            </div>
            <div
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
                width: 200px;
              "
            >
              <span>获拍提醒：</span>
              <el-switch
                style="float: right"
                v-model="user.config.sendOfferSuccessSms"
                active-color="#13ce66"
                inactive-color="#b5b1b1"
                @change="saveUserConfig"
              ></el-switch>
            </div>
            <div
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
                width: 200px;
              "
            >
              <span>账号出价超时提醒：</span>
              <el-switch
                style="float: right"
                v-model="user.config.sendOfferTimoutExceptionSms"
                active-color="#13ce66"
                inactive-color="#b5b1b1"
                @change="saveUserConfig"
              ></el-switch>
            </div>
          </div>
          <div style="font-size: 17px; margin-left: 30px">
            <div style="margin-bottom: 5px; color: maroon">
              <span>声音设置</span>
            </div>
            <div
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
                width: 200px;
              "
            >
              <span>获拍提示音：</span>
              <el-switch
                style="float: right"
                v-model="user.config.offerSuccessSound"
                active-color="#13ce66"
                inactive-color="#b5b1b1"
                @change="saveUserConfig"
              ></el-switch>
            </div>
            <div
              style="
                margin-left: 30px;
                font-size: 15px;
                margin-bottom: 10px;
                color: #868585;
                width: 200px;
              "
            >
              <span>未拍中提示音：</span>
              <el-switch
                style="float: right"
                v-model="user.config.offerFailedSound"
                active-color="#13ce66"
                inactive-color="#b5b1b1"
                @change="saveUserConfig"
              ></el-switch>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <description :describeDialogVis.sync="describeDialogVis"></description>
    <!-- <member :memberDialogVis.sync="memberDialogVis"></member> -->
    <tips :tipsDialogVis.sync="tipsDialogVis"></tips>
    <ModifyPassword
      :modifyPasswordDialogVis.sync="modifyPasswordDialogVis"
    ></ModifyPassword>
    <audio
      controls="controls"
      hidden
      src="./success.mp3"
      preload
      ref="audioS"
    ></audio>

    <audio
      controls="controls"
      hidden
      src="./error.mp3"
      preload
      ref="audioF"
    ></audio>

    <audio
      controls="controls"
      hidden
      src="./error.mp3"
      preload
      ref="audioE"
    ></audio>
    <audio
      controls="controls"
      hidden
      src="./success1.mp3"
      preload
      ref="audioN"
    ></audio>
  </div>
</template>

<script>
import { Dropdown, DropdownMenu, DropdownItem } from "element-ui";
import { mapState, mapActions } from "vuex";
import {
  getUser,
  saveUserConfig,
  getNewMessages,
  modifyPhoneNumber,
} from "./api";
import { fmtDate } from "../../../../views/system/index/components/d2-page-cover/dataFormat.js";
import Description from "../../../../views/system/index/components/d2-page-cover/Description.vue";
import Member from "../../../../views/system/index/components/d2-page-cover/Member.vue";
import Tips from "../../../../views/system/index/components/d2-page-cover/Tips.vue";
import ModifyPassword from "../../../../views/system/index/components/d2-page-cover/ModifyPassword.vue";

export default {
  components: {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Description,
    Member,
    Tips,
    ModifyPassword,
  },
  data() {
    return {
      editPhoneNumber: false,
      memberDialogVis: false,
      tipsDialogVis: false,
      modifyPasswordDialogVis: false,
      describeDialogVis: false,
      drawer: false,
      direction: "rtl",
      timer: null,
      timer1: null,
      user: {
        quota: 0,
        inviteCode: "",
        member: { name: "" },
        expireTime: 0,
        config: {
          sendOfferSuccessSms: false,
          sendOfferTimoutExceptionSms: false,
          offerSuccessSound: true,
          offerFailedSound: true,
          offerExceptionSound: true,
          autoChangeMainAccount: false,
        },
      },
    };
  },
  computed: {
    ...mapState("d2admin/user", ["info"]),
    greetings: {
      get() {
        let now = new Date();
        let hour = now.getHours();
        if (hour < 6) {
          return "凌晨好！";
        } else if (hour < 9) {
          return "早上好！";
        } else if (hour < 11) {
          return "上午好！";
        } else if (hour < 13) {
          return "中午好！";
        } else if (hour < 17) {
          return "下午好！";
        } else if (hour < 19) {
          return "傍晚好！";
        } else if (hour < 24) {
          return "晚上好！";
        }
      },
    },
  },
  created() {
    this.getUser();
    this.getNewMessages();
    let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      this.getUser();
    }, 60000);

    this.timer1 = setInterval(() => {
      this.getNewMessages();
    }, 60000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }

    if (this.timer1) {
      clearInterval(this.timer1); // 在Vue实例销毁前，清除我们的定时器
    }
  },
  methods: {
    openNotification(title, content, msgType, type) {
      var time = 10000;
      if (type === "customedNews") {
        time = 0;
      }
      this.$notify({
        title: title,
        message: content,
        type: msgType,
        duration: time,
      });
    },
    playS() {
      this.$refs.audioS.play();
    },
    playF() {
      this.$refs.audioF.play();
    },
    playE() {
      this.$refs.audioE.play();
    },
    playN() {
      this.$refs.audioN.play();
    },
    getNewMessages() {
      getNewMessages().then((res) => {
        if (res.code === 0) {
          for (var i = 0; i < res.data.length; i++) {
            if (
              res.data[i].type === "successOffer" &&
              this.user.config.offerSuccessSound === true
            ) {
              this.playS();
            }
            // else if (
            //   res.data[i].type === "failedOffer" &&
            //   this.user.config.offerFailedSound === true
            // ) {
            //   this.playF();
            // }
            else if (res.data[i].type === "customedNews") {
              if (res.data[i].msgType === "success") {
                this.playN();
              } else if (res.data[i].msgType === "warning") {
                this.playF();
              }
            }
            if (res.data[i].type !== "failedOffer") {
              this.openNotification(
                res.data[i].title,
                res.data[i].content,
                res.data[i].msgType,
                res.data[i].type
              );
            }
          }
        }
      });
    },
    openModifyPassword() {
      this.modifyPasswordDialogVis = true;
    },
    openTips() {
      this.tipsDialogVis = true;
    },
    openMember() {
      this.memberDialogVis = true;
    },
    openDescribe() {
      this.describeDialogVis = true;
    },
    openMore() {
      this.drawer = true;
    },
    modifyPhoneNumber() {
      this.editPhoneNumber = false;
      modifyPhoneNumber(this.info.phoneNumber).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: res.message,
            type: "success",
          });
        }
        if (res.code === 1) {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
        this.getUser();
      });
    },
    saveUserConfig() {
      saveUserConfig(this.user.config).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: res.message,
            type: "success",
          });
        }
        if (res.code === 1) {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
        this.getUser();
      });
    },
    fmtDate(obj) {
      return fmtDate(obj, true, true, true, false);
    },
    getUser() {
      if (this.info.uuid) {
        getUser(this.info.uuid).then((res) => {
          if (res.code === 0) {
            this.user = res.data;
          }
        });
      }
    },
    ...mapActions("d2admin/account", ["logout"]),
    /**
     * @description 登出
     */
    logOff() {
      this.logout({
        vm: this,
        confirm: true,
      });
    },
    pwdUpdate() {
      this.$router.push({
        path: "/rbac/user/passwordUpdate",
      });
    },
  },
};
</script>
<style>
.primary {
  color: #ff9b00 !important;
}

.el-drawer {
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: papayawhip !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.el-drawer__header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #72767b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px !important;
  padding: 20px 20px 0;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-user/index.vue"
}
</vue-filename-injector>
