import request from "@/plugin/axios";

export function getAuctions(data, pageNum, pageSize) {
  return request({
    url: "/auction/page/" + pageNum + "/" + pageSize,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function getHistoryAuctions(data, pageNum, pageSize) {
  return request({
    url: "/auction/history/page/" + pageNum + "/" + pageSize,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function getTasks(brandName, productName, pageNum, pageSize) {
  return request({
    url:
      "/task/page/" +
      pageNum +
      "/" +
      pageSize +
      "?brandName=" +
      brandName +
      "&productName=" +
      productName,
    method: "get",
  });
}
export function getQianggouTasks(brandName, productName, pageNum, pageSize) {
  return request({
    url:
      "/qianggou/task/page/" +
      pageNum +
      "/" +
      pageSize +
      "?brandName=" +
      brandName +
      "&productName=" +
      productName,
    method: "get",
  });
}
export function getSuperTasks(brandName, productName, pageNum, pageSize) {
  return request({
    url:
      "/task/super/task/page/" +
      pageNum +
      "/" +
      pageSize +
      "?brandName=" +
      brandName +
      "&productName=" +
      productName,
    method: "get",
  });
}

export function getSuperQianggouTasks(
  brandName,
  productName,
  pageNum,
  pageSize
) {
  return request({
    url:
      "/qianggou/task/super/task/page/" +
      pageNum +
      "/" +
      pageSize +
      "?brandName=" +
      brandName +
      "&productName=" +
      productName,
    method: "get",
  });
}
export function getTasksHistory(
  productName,
  pageNum,
  pageSize,
  success,
  lowest20Price
) {
  return request({
    url:
      "/task/history/task/page/" +
      pageNum +
      "/" +
      pageSize +
      "?productName=" +
      productName +
      "&success=" +
      success +
      "&lowest20Price=" +
      lowest20Price,
    method: "get",
  });
}

export function getSuperTasksHistory(
  productName,
  pageNum,
  pageSize,
  success,
  missed,
  lowest20Price
) {
  return request({
    url:
      "/task/super/history/task/page/" +
      pageNum +
      "/" +
      pageSize +
      "?productName=" +
      productName +
      "&success=" +
      success +
      "&missed=" +
      missed +
      "&lowest20Price=" +
      lowest20Price,
    method: "get",
  });
}
export function getPrices(queryData, pageNum, pageSize) {
  return request({
    url: "/price/page/" + pageNum + "/" + pageSize,
    method: "post",
    data: queryData,
  });
}

export function saveTask(data) {
  return request({
    url: "/task/save/task",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function saveQianggouTask(data) {
  return request({
    url: "/qianggou/task/save/task",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}
export function superSaveTask(data) {
  return request({
    url: "/task/super/save/task",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function superSaveQianggouTask(data) {
  return request({
    url: "/qianggou/task/super/save/task",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}
export function savePrice(data, fakeId) {
  return request({
    url: "/price/save/price",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      fakeId: fakeId ? fakeId : "",
    },
    data,
  });
}
export function removeTask(_id) {
  return request({
    url: "/task/remove/task?_id=" + _id,
    method: "get",
  });
}
export function removeQianggouTask(_id) {
  return request({
    url: "/qianggou/task/remove/task?_id=" + _id,
    method: "get",
  });
}

export function removeTaskByCategory(auctionId) {
  return request({
    url: "/task/remove/category/task?auctionId=" + auctionId,
    method: "get",
  });
}

export function removeTaskByUser() {
  return request({
    url: "/task/remove/user/task",
    method: "get",
  });
}
export function removeHistoryTask(_id) {
  return request({
    url: "/task/remove/history/task?_id=" + _id,
    method: "get",
  });
}

export function removePrice(_id) {
  return request({
    url: "/price/remove/price?_id=" + _id,
    method: "get",
  });
}

export function createTasks() {
  let url = "/task/reset/tasks";
  return request({
    url: url,
    method: "get",
  });
}

export function updateAuctions() {
  let url = "/auction/init/data";
  return request({
    url: url,
    method: "get",
  });
}

export function saveProperty(data) {
  return request({
    url: "/property/save/property",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function getProperty() {
  return request({
    url: "/property/get/property",
    method: "get",
  });
}

export function createTask(data) {
  return request({
    url: "/task/create/task",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function createQianggouTask(skuId, accountUsername) {
  return request({
    url:
      "/qianggou/task/create/task?skuId=" +
      skuId +
      "&accountUsername=" +
      accountUsername,
    method: "post",
  });
}

export function saveUser(data) {
  return request({
    url: "/user/save/user",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function getUsers(
  username,
  name,
  phoneNumber,
  level,
  valid,
  pageNum,
  pageSize
) {
  return request({
    url:
      "/user/page/" +
      pageNum +
      "/" +
      pageSize +
      "?username=" +
      username +
      "&phoneNumber=" +
      phoneNumber +
      "&level=" +
      level +
      "&name=" +
      name +
      "&valid=" +
      valid,
    method: "get",
  });
}

export function getUserList(valid, pageNum, pageSize) {
  return request({
    url: "/user/page/" + pageNum + "/" + pageSize + "?valid=" + valid,
    method: "get",
  });
}
export function removeUser(uuid) {
  return request({
    url: "/user/remove/user?uuid=" + uuid,
    method: "get",
  });
}
//由nginx转发到duobaodao网站，直接请求跨域错误
export function getCurrentPrice(data) {
  return request({
    url: "auction/current/price",
    method: "post",
    data,
  });
}

export function getConsumes(uuid, pageNum, pageSize) {
  return request({
    url: "/consume/page/" + pageNum + "/" + pageSize + "?uuid=" + uuid,
    method: "get",
  });
}
export function getAllUserConsumes(pageNum, pageSize) {
  return request({
    url: "/consume/super/page/" + pageNum + "/" + pageSize,
    method: "get",
  });
}
export function registerMember(uuid, level, type) {
  return request({
    url:
      "/user/register/member?uuid=" +
      uuid +
      "&level=" +
      level +
      "&type=" +
      type,
    method: "get",
  });
}

export function recharge(uuid, amoount) {
  return request({
    url: "/quota/recharge/quota?uuid=" + uuid + "&amoount=" + amoount,
    method: "get",
  });
}

export function getAccounts(
  uuid,
  username,
  pageNum,
  pageSize,
  isBeijiankuOrderAccount
) {
  return request({
    url:
      "/account/page/" +
      pageNum +
      "/" +
      pageSize +
      "?uuid=" +
      uuid +
      "&username=" +
      username +
      "&isBeijiankuOrderAccount=" +
      isBeijiankuOrderAccount,
    method: "get",
  });
}
export function getSuperAccounts(
  uuid,
  username,
  pageNum,
  pageSize,
  isBeijiankuOrderAccount
) {
  return request({
    url:
      "/account/super/page/" +
      pageNum +
      "/" +
      pageSize +
      "?uuid=" +
      uuid +
      "&username=" +
      username +
      "&isBeijiankuOrderAccount=" +
      isBeijiankuOrderAccount,
    method: "get",
  });
}

export function saveAccount(data, fakeId) {
  return request({
    url: "/account/save/account",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      fakeId: fakeId ? fakeId : "",
    },
    data,
  });
}

export function getBidRecords(auctionId) {
  return request({
    url: "/auction/bid-records?auctionId=" + auctionId,
    method: "get",
  });
}

export function getConsumeDetail() {
  return request({
    url: "/consume/detail",
    method: "get",
  });
}
export function getAllConsumeDetail() {
  return request({
    url: "/consume/super/detail",
    method: "get",
  });
}
export function testAccount(data, fakeId) {
  return request({
    url: "/account/test/account",
    method: "post",
    headers: {
      fakeId: fakeId ? fakeId : "",
    },
    data,
  });
}
export function testUserAccounts() {
  return request({
    url: "/account/test/user/accounts",
    method: "get",
  });
}

export function removeAccount(id, fakeId) {
  return request({
    url: "/account/remove/account?accountId=" + id,
    method: "get",
    headers: {
      fakeId: fakeId ? fakeId : "",
    },
  });
}
export function getHistoryPrices(data) {
  return request({
    url: "/auction/history/prices?",
    data,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function loginQr(accountId) {
  return request({
    url: "/account/login/qr?accountId=" + accountId,
    method: "get",
  });
}

export function checkQr(accountId) {
  return request({
    url: "/account/qr/check?accountId=" + accountId,
    method: "get",
  });
}

export function getAuction(auctionId) {
  return request({
    url: "/auction/" + auctionId,
    method: "get",
  });
}

export function getStatistics() {
  return request({
    url: "/statistics/detail",
    method: "get",
  });
}

export function getOfferDetail() {
  return request({
    url: "/statistics/offer/detail",
    method: "get",
  });
}

export function getHistoryOfferDetail() {
  return request({
    url: "/statistics/offer/history/detail",
    method: "get",
  });
}

export function reward(amt) {
  return request({
    url: "/quota/reward?amt=" + amt,
    method: "get",
  });
}

//给张小北的api接口
export function getKey(keys, onlyAutoOrder, pageNum, pageSize) {
  return request({
    url:
      "/keys/page/" +
      pageNum +
      "/" +
      pageSize +
      "?keys=" +
      keys +
      "&onlyAutoOrder=" +
      onlyAutoOrder,
    method: "get",
  });
}

export function getSuperKey(keys, onlyAutoOrder, pageNum, pageSize) {
  return request({
    url:
      "/keys/super/page/" +
      pageNum +
      "/" +
      pageSize +
      "?keys=" +
      keys +
      "&onlyAutoOrder=" +
      onlyAutoOrder,
    method: "get",
  });
}
export function removeKey(_id) {
  return request({
    url: "/keys/remove/key?_id=" + _id,
    method: "get",
  });
}
export function saveKey(data) {
  return request({
    url: "/keys/save/key",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function superSaveKey(data) {
  return request({
    url: "/keys/super/save/key",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function resetTaskPrice(data) {
  return request({
    url: "/price/reset/task/price",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function modifyPassword(oldPassword, newPassword) {
  return request({
    url:
      "/user/modify/password?oldPassword=" +
      oldPassword +
      "&newPassword=" +
      newPassword,
    method: "get",
  });
}
export function getPrice(_id) {
  return request({
    url: "/price/" + _id,
    method: "get",
  });
}
export function getTask(_id) {
  return request({
    url: "/task/" + _id,
    method: "get",
  });
}

export function getSeckills(data, pageNum, pageSize) {
  return request({
    url: "/seckill/page/" + pageNum + "/" + pageSize,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function offerSeckill(id, skuId, cookie) {
  return request({
    url:
      "/seckill/task/offer?id=" + id + "&skuId=" + skuId + "&cookie=" + cookie,
    method: "get",
  });
}

export function createSeckillTask(data) {
  return request({
    url: "/seckill/create/task",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}
export function removePrices(_ids) {
  return request({
    url: "/price/remove/prices?_ids=" + _ids,
    method: "get",
  });
}
export function saveHistoryTask(data) {
  return request({
    url: "/task/save/history/task",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function batchCreateLuckyTasks(data) {
  return request({
    url: "/task/batch/createLuckyTasks",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function getAccountOfferDetail(id) {
  return request({
    url: "/account/offer/detail?accountId=" + id,
    method: "get",
  });
}

export function downloadExcelXls(days, onlySuccess) {
  return request({
    url:
      "/task/export/downloadExcelXls?days=" +
      days +
      "&onlySuccess=" +
      onlySuccess,
    method: "get",
    responseType: "blob",
  });
}

export function syncRunnerServices() {
  return request({
    url: "/eureka/sync-runner-services",
    method: "post",
  });
}

export function getProducts(data, pageNum, pageSize) {
  return request({
    url: "/product/page/" + pageNum + "/" + pageSize,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function getKeysList() {
  return request({
    url: "/Keys/page/" + pageNum + "/" + pageSize,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function addBlacklist(usedNo) {
  return request({
    url: "/product/add/blacklist?usedNo=" + usedNo,
    method: "get",
  });
}

export function removeBlacklist(usedNo) {
  return request({
    url: "/product/remove/blacklist?usedNo=" + usedNo,
    method: "get",
  });
}
export function getAddrList() {
  return request({
    url: "/keys/get/addrList",
    method: "get",
  });
}

export function getStockSearchAPICount() {
  return request({
    url: "/beijianku/monitor/stock/search/info",
    method: "get",
  });
}

export function getStockSearchAccounts() {
  return request({
    url: "/beijianku/monitor/stock/search/accounts",
    method: "get",
  });
}

export function getAutoOrderProducts(data, pageNum, pageSize) {
  return request({
    url: "/product/auto/order/page/" + pageNum + "/" + pageSize,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function getRunners() {
  return request({
    url: "/beijianku/monitor/runners",
    method: "get",
  });
}

export function saveAutoOrderProduct(data) {
  return request({
    url: "/product/save/autoOrderProduct",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}
export function getOrders(data, pageNum, pageSize) {
  return request({
    url: "/order/page/" + pageNum + "/" + pageSize,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}
export function addAutoOrderProduct(keys, usedNo) {
  return request({
    url: "/product/add/autoOrderProduct?keys=" + keys + "&usedNo=" + usedNo,
    method: "post",
  });
}
export function removeAutoOrderProduct(usedNo) {
  return request({
    url: "/product/remove/autoOrderProduct?usedNo=" + usedNo,
    method: "post",
  });
}

export function getProductSea(data, pageNum, pageSize) {
  return request({
    url: "/product/sea/page/" + pageNum + "/" + pageSize,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function getBlacklistProducts(data, pageNum, pageSize) {
  return request({
    url: "/product/blacklist/page/" + pageNum + "/" + pageSize,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function saveBlacklistProduct(data) {
  return request({
    url: "/product/save/blacklist",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function refreshHasStockAccounts() {
  return request({
    url: "/account/syncAppLoginedAccounts",
    method: "get",
  });
}

export function validBeijiankuOrderAccount(username, valid, neixiaoOrder) {
  return request({
    url:
      "/account/validBeijiankuOrderAccount?username=" +
      username +
      "&valid=" +
      valid +
      "&neixiaoOrder=" +
      neixiaoOrder,
    method: "post",
  });
}

export function saveAsBeijiankuAccount(data) {
  return request({
    url: "/account/save/AsBeijianku/account",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

export function downloadExcelXlsOrders(days, notBlacklist) {
  return request({
    url:
      "/order/export/downloadExcelXls?days=" +
      days +
      "&notBlacklist=" +
      notBlacklist,
    method: "get",
    responseType: "blob",
  });
}
export function freshKey(data) {
  return request({
    url: "/keys/fresh/key",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}
export function saveOrderRemark(_id, remark) {
  return request({
    url: "/order/save/remark?_id=" + _id + "&remark=" + remark,
  });
}
export function testAccountLogined(data, fakeId) {
  return request({
    url: "/account/isLogined",
    method: "post",
    headers: {
      fakeId: fakeId ? fakeId : "",
    },
    data,
  });
}
export function testAccountsLogined() {
  return request({
    url: "/account/accounts/isLogined",
    method: "post",
  });
}
