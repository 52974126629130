<template>
  <el-dialog
    :visible.sync="modifyPasswordDialogVisData"
    :close-on-click-modal="true"
    append-to-body
    center
    title="修改密码"
    width="20%"
  >
    <el-form
      ref="form"
      label-position="left"
      :rules="rules"
      :model="user"
      size="default"
    >
      <el-form-item prop="password" label-width="旧密码">
        <el-input type="password" v-model="user.password" placeholder="旧密码">
          <i slot="prepend" class="fa fa-keyboard-o"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="newPassword" label-width="新密码">
        <el-input
          @keydown.enter.native="submit"
          type="password"
          v-model="user.newPassword"
          placeholder="新密码"
        >
          <i slot="prepend" class="fa fa-keyboard-o"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="newPassword" label-width="确认新密码">
        <el-input
          @keydown.enter.native="submit"
          type="password"
          v-model="user.newPassword1"
          placeholder="确认新密码"
        >
          <i slot="prepend" class="fa fa-keyboard-o"></i>
        </el-input>
      </el-form-item>
      <el-row style="text-align:center">
        <el-button
          size="default"
          @click="submit"
          type="primary"
          class="button-login"
        >
          提交
        </el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>
import { modifyPassword } from "./api";
export default {
  name: "ModifyPassword",
  data() {
    return {
      user: {
        password: "",
        newPassword: "",
        newPassword1: "",
      },
      modifyPasswordDialogVisData: this.modifyPasswordDialogVis,
      rules: {
        newPassword: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 30,
            message: "长度在 6 到 30 个字符",
          },
          {
            pattern: /^(\w){6,20}$/,
            message: "只能输入6-20个字母、数字、下划线",
          },
        ],
        newPassword1: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 30,
            message: "长度在 6 到 30 个字符",
          },
          {
            pattern: /^(\w){6,20}$/,
            message: "只能输入6-20个字母、数字、下划线",
          },
        ],
      },
    };
  },
  props: {
    modifyPasswordDialogVis: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.user.newPassword !== this.user.newPassword1) {
            this.$message({
              message: "两次密码输入不一致！",
              type: "error",
            });
            return;
          }
          if (!this.user.newPassword || !this.user.newPassword) {
            this.$message({
              message: "密码不能为空！",
              type: "error",
            });
            return;
          }
          modifyPassword(this.user.password, this.user.newPassword).then(
            (res) => {
              if (res.code === 0) {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.modifyPasswordDialogVisData = false;
              }
            }
          );
        } else {
          // 登录表单校验失败
          this.$message.error("请检查输入格式！");
        }
      });
    },
  },
  watch: {
    modifyPasswordDialogVisData(n, o) {
      this.$emit("update:modifyPasswordDialogVis", n);
    },
    modifyPasswordDialogVis(n, o) {
      this.modifyPasswordDialogVisData = n;
    },
  },
};
</script>

<style scoped>
.yellowTime {
  margin-left: 5px;
  font-size: 15px;
  color: #ffc02d;
}
.greenTime {
  margin-left: 5px;
  font-size: 15px;
  color: #00f335;
}
.yellowPrice {
  color: #ffc02d;
}
.greenPrice {
  color: #00f335;
}
.greyPrice {
  color: #000000;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/system/index/components/d2-page-cover/ModifyPassword.vue"
}
</vue-filename-injector>
