import store from "@/store";
import axios from "axios";
import { Message } from "element-ui";
import util from "@/libs/util";
import router from "@/router/index";
// 创建一个错误
function errorCreate(msg) {
  const error = new Error(msg);
  errorLog(error);
  throw error;
}

// 记录和显示错误
function errorLog(error) {
  // 添加到日志
  store.dispatch("d2admin/log/push", {
    message: "数据请求异常",
    type: "danger",
    meta: {
      error
    }
  });
  // 打印到控制台
  if (process.env.NODE_ENV === "development") {
    util.log.danger(">>>>>> Error >>>>>>");
    console.log(error);
  }
  // 显示提示
  Message({
    message: error.message,
    type: "error",
    duration: 5 * 1000
  });
}

// 创建一个 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 60000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在请求发送之前做一些处理
    const token = util.cookies.get("token");
    // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    config.headers["X-Token"] = token;
    return config;
  },
  error => {
    // 发送失败
    console.log(error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    // 这个状态码是和后端约定的
    const { code, message } = dataAxios;
    // 根据 code 进行判断
    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
      return dataAxios;
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (code) {
        case 0:
          // [ 示例 ] code === 0 代表没有错误
          return dataAxios;
        case 1:
          return dataAxios;
        case 200:
          return dataAxios;
        default:
          // 不是正确的 code
          //errorCreate(`${dataAxios.msg}: ${response.config.url}`)
          return dataAxios;
      }
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误";
          break;
        case 401:
          error.message = "登录已过期，请重新登录！";
          router.push({
            name: "login"
          });
          break;
        case 403:
          router.push({
            name: "login"
          });
          error.message = "该账号已冻结，请联系管理员（18630715494微信同号）开通会员后再登录！";
          break;
        case 404:
          error.message = `哎哟，系统打了个小盹儿，稍后请刷新一下吧....`;
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "糟糕！系统好像有点忙哦..";
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网关超时";
          break;
        case 505:
          error.message = "HTTP版本不受支持";
          break;
        default:
          break;
      }
    }
    errorLog(error);
    return Promise.reject(error);
  }
);

export default service;
