
// 菜单 侧边栏
export const menuAside = [
]

// 菜单 顶栏
export const menuHeader = [
  {
    path: '/index',
    title: '',
  }
]
