<template>
  <el-dialog
    title="打赏软件作者，赏金将直接从余额里扣除"
    :visible.sync="rewardDialogVisData"
    :close-on-click-modal="true"
    append-to-body
    center
    width="30%"
  ><div style="text-align:center"><span style="font-size:16px;color:red;text-align:center">据说下面这几个神奇按钮可以增加“捡漏”几率....</span> </div>
    <el-row style="text-align:center;margin:30px 0px;">
      <el-button type="info" style="width:220px;" @click="reward(1.66)"
        ><span style="font-size:20px">1.66元，继续努力</span></el-button
      >
    </el-row>
    <el-row style="text-align:center;margin:30px 0px;">
      <el-button type="primary" style="width:220px;" @click="reward(6.66)"
        ><span style="font-size:20px">6.66元，软件666</span></el-button
      >
    </el-row>
    <el-row style="text-align:center;margin:30px 0px;">
      <el-button type="warning" style="width:220px;" @click="reward(16.66)"
        ><span style="font-size:20px">16.66元，喝杯茶吧</span></el-button
      >
    </el-row>
    <el-row style="text-align:center;margin:30px 0px;">
      <el-button type="danger" style="width:220px;" @click="reward(66.66)"
        ><span style="font-size:20px">66.66元，超级给力</span></el-button
      >
    </el-row>
  </el-dialog>
</template>
<script>
import { reward } from "./api.js";

export default {
  name: "Reward",
  data() {
    return {
      rewardDialogVisData: false
    };
  },
  props: {
    rewardDialogVis: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    reward(amt) {
      reward(amt).then(res => {
        if (res.code === 0) {
          this.$message({
            message: res.message,
            type: "success"
          });
        }
        if (res.code === 1) {
          this.$message({
            message: res.message,
            type: "error"
          });
        }
      });
    }
  },
  watch: {
    rewardDialogVisData(n, o) {
      this.$emit("update:rewardDialogVis", n);
    },
    rewardDialogVis(n, o) {
      this.rewardDialogVisData = n;
    }
  }
};
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/system/index/components/d2-page-cover/Reward.vue"
}
</vue-filename-injector>
