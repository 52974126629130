export function fmtDate(obj,year,month,day,time) {
	if(!obj){
		return null
	}
	var date = new Date(obj);
	var y = 1900 + date.getYear();
	var m = "0" + (date.getMonth() + 1);
	var d = "0" + date.getDate();
	var h = date.getHours();
	if(h < 10) {
		h = "0" + h;
	}
	var min = date.getMinutes();
	if(min < 10) {
		min = "0" + min;
	}
	var s = date.getSeconds();
	if(s < 10) {
		s = "0" + s;
	}
	let dateString=''
	if (year) {
		dateString+=y + "-" 
	}
	if (month) {
		dateString+=m.substring(m.length - 2, m.length) + "-"
	}
	if (day) {
		dateString+= d.substring(d.length - 2, d.length) + " "
	}
	if (time) {
		dateString+=h + ":" + min + ":" + s;
	}
	return dateString
}