import request from "@/plugin/axios";

export function getUser(uuid) {
  return request({
    url: "/user/user?uuid=" + uuid,
    method: "get"
  });
}
export function saveUserConfig(data) {
  return request({
    url: "/user/save/config",
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    data
  });
}


export function getNewMessages() {
  return request({
    url: "/message/new",
    method: "get"
  });
}

export function modifyPhoneNumber(phoneNumber){
  return request({
    url:"/user/modify/phoneNumber?phoneNumber=" +phoneNumber,
    method: "post",
  });
}