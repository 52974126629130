<template>
  <el-dialog
    :visible.sync="memberDialogVisData"
    :close-on-click-modal="true"
    append-to-body
    center
    width="80%"
  >
    <h2 style="color:red">会员充值</h2>
    <p style="font-size:18px;color:#000;margin-left:30px;line-height:27px;">
      本软件收费模式是<strong>月度会员制</strong>，一次充值根据充值数额分为白银、黄金、铂金、大客户四级会员，账号内会带有等量充值余额。收费规则如下：<br />
      <span style="color:red"
        >会员降级制度：会员充值后有效期为30天，到期后会员级别自动降一级，有效期为新的30天。直到掉到白银会员，白银会员永不过期。</span
      >
    </p>
    <el-table
      border
      :data="memberTableData"
      style="width: 100%;text-align:center"
      :span-method="objectSpanMethod"
    >
      <el-table-column
        align="center"
        prop="costItem"
        label="会员权益"
        width="250"
      >
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.costItem }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="memberShip"
        label="规则"
        width="200"
      >
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.memberShip }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="level0" label="非会员">
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.level0 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="level1" label="白银会员">
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.level1 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="level2" label="黄金会员">
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.level2 }}
          </span>
        </template> </el-table-column
      ><el-table-column align="center" prop="level3" label="铂金会员">
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.level3 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="level4" label="大客户">
        <template slot-scope="scope">
          <span style="font-size:16px;">
            {{ scope.row.level4 }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <h2>
      转账时请备注手机号或者会员名和要开通的会员级别，例如：至尊宝，黄金，若是续费请备注“续费”
    </h2>
    <div style="text-align:center">
      <img src="./image/zhifubao.png" />
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "Member",
  data() {
    return {
      memberDialogVisData: this.memberDialogVis,
      memberTableData: [
        // {
        //   memberShip: "夺宝失败资源使用费（固定消费，单位：元）",
        //   level0: "0",
        //   level1: "0.1",
        //   level2: "0.1",
        //   level3: "0.1",
        //   level4: "0.1"
        // },
        {
          costItem: "夺宝成功费（成交价*费率）",
          memberShip: "夺宝成功费率",
          level0: "0",
          level1: "0.5%",
          level2: "0.4%",
          level3: "0.3%",
          level4: "0.2%",
        },
        {
          costItem: "夺宝成功收费:成交价*费率",
          memberShip: "最低收费",
          level0: "0",
          level1: "1",
          level2: "1",
          level3: "1",
          level4: "1",
        },
        {
          costItem: "-",
          memberShip: "最高收费上限",
          level0: "0",
          level1: "6",
          level2: "5",
          level3: "4",
          level4: "3",
        },
        {
          costItem: "资源使用费",
          memberShip:
            "因夺宝任务共享云端服务器资源和性能，未获拍的任务收取0.1元服务器资源使用费（出价超时、失手和夺宝成功不收取该费用）。",
          level0: "0",
          level1: "10",
          level2: "10",
          level3: "10",
          level4: "10",
        },
        {
          costItem: "幸运捡漏费",
          memberShip:
            "如果您的获拍价是该商品近20次历史成交新低价格，恭喜您幸运捡漏！软件收取幸运捡漏费：（原20次历史最低价-当前获拍价）* 10%，上限10元。",
          level0: "0",
          level1: "10",
          level2: "10",
          level3: "10",
          level4: "10",
        },
        {
          costItem: "会员有效期",
          memberShip: "会员降级制度",
          level0: "3天免费体验",
          level1: "30天",
          level2: "黄金30天，白银不限制",
          level3: "铂金30天，黄金30天，白银不限制",
          level4: "大客户30天，铂金30天，黄金30天，白银不限制",
        },
        {
          costItem: "到账金额",
          memberShip: "与充值金额一致",
          level0: "0",
          level1: "30",
          level2: "100",
          level3: "300",
          level4: "500",
        },
        {
          costItem: "充值价格",
          memberShip: "-",
          level0: "0",
          level1: "30",
          level2: "100",
          level3: "300",
          level4: "500",
        },
      ],
    };
  },
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 3,
            colspan: 1,
          };
        } else if (rowIndex === 3) {
          return {
            rowspan: 1,
            colspan: 1,
          };
        } else if (rowIndex < 4) {
          return {
            rowspan: 1,
            colspan: 0,
          };
        }
      } else if (columnIndex === 1) {
        if (rowIndex === 3 || rowIndex === 4) {
          return {
            rowspan: 1,
            colspan: 6,
          };
        }
      }
    },
  },
  props: {
    memberDialogVis: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    memberDialogVisData(n, o) {
      this.$emit("update:memberDialogVis", n);
    },
    memberDialogVis(n, o) {
      this.memberDialogVisData = n;
    },
  },
};
</script>

<style scoped>
.yellowTime {
  margin-left: 5px;
  font-size: 15px;
  color: #ffc02d;
}
.greenTime {
  margin-left: 5px;
  font-size: 15px;
  color: #00f335;
}
.yellowPrice {
  color: #ffc02d;
}
.greenPrice {
  color: #00f335;
}
.greyPrice {
  color: #000000;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/views/system/index/components/d2-page-cover/Member.vue"
}
</vue-filename-injector>
