<template>
  <div
    class="d2-layout-header-aside-group"
    :style="styleLayoutMainGroup"
    :class="{ grayMode: grayActive }"
  >
    <!-- 半透明遮罩 -->
    <div class="d2-layout-header-aside-mask"></div>
    <!-- 主体内容 -->
    <div class="d2-layout-header-aside-content" flex="dir:top">
      <!-- 顶栏 -->
      <div
        class="d2-theme-header"
        :style="{
          opacity: this.searchActive ? 0.5 : 1,
        }"
        flex-box="0"
        flex
      >
        <div class="d2-header-right" flex-box="0">
          <!-- <span style="color:red;margin-left:20px;font-size:16px">
            [最新公告]:近期获拍短信不能发送，现已修复，广而告之。 </span
          ><span style="color:gray;margin-left:20px;font-size:16px"></span> -->
        </div>
        <!-- <div
          class="logo-group"
          :style="{ width: asideCollapse ? asideWidthCollapse : asideWidth }"
          flex-box="0"
        >
          <img
            v-if="asideCollapse"
            :src="
              `${$baseUrl}image/theme/${themeActiveSetting.name}/logo/icon-only.png`
            "
          />
          <img
            v-else
            :src="
              `${$baseUrl}image/theme/${themeActiveSetting.name}/logo/all.png`
            "
          />
        </div> -->
        <!-- <div class="toggle-aside-btn" @click="handleToggleAside" flex-box="0">
          <d2-icon name="bars"/>
        </div> -->
        <d2-menu-header flex-box="1" />
        <!-- 顶栏右侧 -->
        <div class="d2-header-right" flex-box="0">
          <!-- <el-button type="text" @click="showReward"
            ><span style="color:red;margin-right:20px;font-size:16px"
              >打赏作者</span
            ></el-button
          > -->
          <!-- <el-button type="text" @click="showTips"
            ><span style="color:red;margin-right:20px;font-size:16px"
              >常见问题</span
            ></el-button
          >
          <a
            style="margin-top: 3px;
    margin-right: 15px;"
            target="_blank"
            href="//shang.qq.com/wpa/qunwpa?idkey=2e2f0ec6f969e29f463c86b5cfc11ddbaea1b0134fc1a1eba79df7a27f183909"
            ><img
              border="0"
              src="//pub.idqqimg.com/wpa/images/group.png"
              alt="夺宝屋vip群"
              title="夺宝屋vip群"
          /></a> -->
          <!-- 如果你只想在开发环境显示这个按钮请添加 v-if="$env === 'development'" -->
          <!-- <d2-header-search @click="handleSearchClick"/> -->
          <!-- <d2-header-log/> -->
          <d2-header-fullscreen />
          <d2-header-theme />
          <!-- <d2-header-size/> -->
          <!-- <d2-header-locales/> -->
          <!-- <d2-header-color/> -->
          <d2-header-user />
        </div>
      </div>
      <!-- 下面 主体 -->
      <div class="d2-theme-container" flex-box="1" flex>
        <!-- 主体 侧边栏 -->
        <!-- <div
          flex-box="0"
          ref="aside"
          class="d2-theme-container-aside"
          :style="{
            width: asideCollapse ? asideWidthCollapse : asideWidth,
            opacity: this.searchActive ? 0.5 : 1
          }">
          <d2-menu-side/>
        </div> -->
        <!-- 主体 -->
        <div class="d2-theme-container-main" flex-box="1" flex>
          <!-- 搜索 -->
          <transition name="fade-scale">
            <div v-if="searchActive" class="d2-theme-container-main-layer" flex>
              <d2-panel-search ref="panelSearch" @close="searchPanelClose" />
            </div>
          </transition>
          <!-- 内容 -->
          <transition name="fade-scale">
            <div
              v-if="!searchActive"
              class="d2-theme-container-main-layer"
              flex="dir:top"
            >
              <!-- tab -->
              <!-- <div class="d2-theme-container-main-header" flex-box="0">
                <d2-tabs/>
              </div> -->
              <!-- 页面 -->
              <div class="d2-theme-container-main-body" flex-box="1">
                <transition :name="transitionActive ? 'fade-transverse' : ''">
                  <keep-alive :include="keepAlive">
                    <router-view />
                  </keep-alive>
                </transition>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <tips :tipsDialogVis.sync="tipsDialogVis"></tips>
      <reward :rewardDialogVis.sync="rewardDialogVis"></reward>
    </div>
  </div>
</template>

<script>
import d2MenuSide from "./components/menu-side";
import d2MenuHeader from "./components/menu-header";
import d2Tabs from "./components/tabs";
import d2HeaderFullscreen from "./components/header-fullscreen";
import d2HeaderLocales from "./components/header-locales";
import d2HeaderSearch from "./components/header-search";
import d2HeaderSize from "./components/header-size";
import d2HeaderTheme from "./components/header-theme";
import d2HeaderUser from "./components/header-user";
import d2HeaderLog from "./components/header-log";
import d2HeaderColor from "./components/header-color";
import Tips from "../../views/system/index/components/d2-page-cover/Tips.vue";
import Reward from "../../views/system/index/components/d2-page-cover/Reward.vue";

import { mapState, mapGetters, mapActions } from "vuex";
import mixinSearch from "./mixins/search";
export default {
  name: "d2-layout-header-aside",
  mixins: [mixinSearch],
  components: {
    d2MenuSide,
    d2MenuHeader,
    d2Tabs,
    d2HeaderFullscreen,
    d2HeaderLocales,
    d2HeaderSearch,
    d2HeaderSize,
    d2HeaderTheme,
    d2HeaderUser,
    d2HeaderLog,
    d2HeaderColor,
    Tips,
    Reward,
  },
  data() {
    return {
      tipsDialogVis: false,
      rewardDialogVis: false,

      // [侧边栏宽度] 正常状态
      asideWidth: "200px",
      // [侧边栏宽度] 折叠状态
      asideWidthCollapse: "65px",
    };
  },
  computed: {
    ...mapState("d2admin", {
      keepAlive: (state) => state.page.keepAlive,
      grayActive: (state) => state.gray.active,
      transitionActive: (state) => state.transition.active,
      asideCollapse: (state) => state.menu.asideCollapse,
    }),
    ...mapGetters("d2admin", {
      themeActiveSetting: "theme/activeSetting",
    }),
    /**
     * @description 最外层容器的背景图片样式
     */
    styleLayoutMainGroup() {
      return {
        ...(this.themeActiveSetting.backgroundImage
          ? {
              backgroundImage: `url('${this.$baseUrl}${this.themeActiveSetting.backgroundImage}')`,
            }
          : {}),
      };
    },
  },
  methods: {
    ...mapActions("d2admin/menu", ["asideCollapseToggle"]),
    /**
     * 接收点击切换侧边栏的按钮
     */
    handleToggleAside() {
      this.asideCollapseToggle();
    },
    showTips() {
      this.tipsDialogVis = true;
    },
    showReward() {
      this.rewardDialogVis = true;
    },
  },
};
</script>

<style lang="scss">
// 注册主题
@import "~@/assets/style/theme/register.scss";
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/layout.vue"
}
</vue-filename-injector>
