import { Message, MessageBox } from "element-ui";
import util from "@/libs/util.js";
import router from "@/router";
import { AccountLogin } from "@api/sys.login";

export default {
  namespaced: true,
  actions: {
    /**
     * @description 登录
     * @param {Object} context
     * @param {Object} payload username {String} 用户账号
     * @param {Object} payload password {String} 密码
     * @param {Object} payload route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    login({ dispatch }, { username = "", password = "" } = {}) {
      return new Promise((resolve, reject) => {
        // 开始请求登录接口
        AccountLogin({
          username,
          password,
        })
          .then(async (res) => {
            if (res.code === 0) {
              if (res.data) {
                Message({
                  message: res.message,
                  type: "success",
                });
              } else {
                Message({
                  message: res.message,
                  type: "error",
                });
              }
              // 设置 cookie 一定要存 uuid 和 token 两个 cookie
              // 整个系统依赖这两个数据进行校验和存储
              // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
              // token 代表用户当前登录状态 建议在网络请求中携带 token
              // 如有必要 token 需要定时更新，默认保存一天
              util.cookies.set("uuid", res.data.uuid);
              util.cookies.set("token", res.data.token);
              // 设置 vuex 用户信息
              await dispatch("d2admin/user/set", res.data, { root: true });
              // 用户登录后从持久化数据加载一系列的设置
              await dispatch("load");

              //  MessageBox.confirm(
              //    '由于JD限制出价策略，目前软件成功率极不稳定，请减小出价区间或者直接按区间最高价设置，比如"323-323"！',
              //    "夺宝屋-软件功能限制公告",
              //    {
              //      showCancelButton: false,
              //      confirmButtonText: "好的",
              //    }
              //  ).then(() => {})
              //    .catch(() => {});

              // 提示会员到期
              var now = new Date().getTime();
              var days = parseInt(
                (res.data.expireTime - now) / (1000 * 60 * 60 * 24)
              );
              if (days < 3) {
                if (res.data.level > 1) {
                  MessageBox.confirm(
                    "您的会员即将到期，剩余[" +
                      days +
                      "]天，到期会员级别将掉1级，夺宝将按新会员费率收取！",
                    "会员到期提醒",
                    {
                      showCancelButton: false,
                      confirmButtonText: "知道了",
                      type: "warning",
                    }
                  )
                    .then(() => {})
                    .catch(() => {});
                } else if (res.data.level === 1){

                }else {
                  MessageBox.confirm(
                    "您的3天试用剩余[" +
                      days +
                      "]天，剩余免费夺宝次数：" +
                      res.data.freeShots +
                      "次，到期或者免费夺宝次数用完，账号将被冻结，请及时开通会员！",
                    "会员到期提醒",
                    {
                      showCancelButton: false,
                      confirmButtonText: "知道了",
                      type: "warning",
                    }
                  )
                    .then(() => {})
                    .catch(() => {});
                }
              }
              //结束
              resolve();
            }
          })
          .catch((err) => {
            console.log("err: ", err);
            reject(err);
          });
      });
    },
    /**
     * @description 注销用户并返回登录页面
     * @param {Object} context
     * @param {Object} payload confirm {Boolean} 是否需要确认
     */
    logout({ commit, dispatch }, { confirm = false } = {}) {
      /**
       * @description 注销
       */
      async function logout() {
        // 删除cookie
        util.cookies.remove("token");
        util.cookies.remove("uuid");
        // 清空 vuex 用户信息
        await dispatch("d2admin/user/set", {}, { root: true });
        // 跳转路由
        router.push({
          name: "login",
        });
      }
      // 判断是否需要确认
      if (confirm) {
        commit("d2admin/gray/set", true, { root: true });
        MessageBox.confirm("确定要注销当前用户吗", "注销用户", {
          type: "warning",
        })
          .then(() => {
            commit("d2admin/gray/set", false, { root: true });
            logout();
          })
          .catch(() => {
            commit("d2admin/gray/set", false, { root: true });
            Message({
              message: "取消注销操作",
            });
          });
      } else {
        logout();
      }
    },
    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} context
     */
    load({ dispatch }) {
      return new Promise(async (resolve) => {
        // DB -> store 加载用户名
        await dispatch("d2admin/user/load", null, { root: true });
        // DB -> store 加载主题
        await dispatch("d2admin/theme/load", null, { root: true });
        // DB -> store 加载页面过渡效果设置
        await dispatch("d2admin/transition/load", null, { root: true });
        // DB -> store 持久化数据加载上次退出时的多页列表
        await dispatch("d2admin/page/openedLoad", null, { root: true });
        // DB -> store 持久化数据加载侧边栏折叠状态
        await dispatch("d2admin/menu/asideCollapseLoad", null, { root: true });
        // DB -> store 持久化数据加载全局尺寸
        await dispatch("d2admin/size/load", null, { root: true });
        // DB -> store 持久化数据加载颜色设置
        await dispatch("d2admin/color/load", null, { root: true });
        // end
        resolve();
      });
    },
  },
};
