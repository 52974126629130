
























































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/system/index/components/d2-page-cover/Member.vue"
}
